<template>
<vx-card class="mt-3 post-card mt-xs-mobile">

    <div class="post-header flex">
    <div class="w-1/12 hidden-sm">
        <vs-avatar class="m-0" :src="userImage" size="35px"></vs-avatar>
    </div>

    <div class="w-11/12">
         <label for="post input" class="input mr-2" >Start converting your thoughts to memes</label>
        <vs-textarea 
            class="post-input mb-2" 
            name="input" 
            v-validate="'required'" 
            data-vv-validate-on="blur" 
            v-model="input" 
            @keyup="resizeTextarea" 
            placeholder="Describe your meme!"
        />
        <!-- <div class="character-details flex items-center">
            <label for="characterDetails" class="character-label mr-2">Character details:</label>
            <vs-input
                id="characterDetails"
                class="character-input"
                v-model="characterDetails"
                placeholder="ex: a woman, wearing a white T-shirt, black loose hair"
                clearable

            />
        </div> -->
        
       

</div>
 </div>

 <!-- Select Style -->
  <!-- <div class="vx-col w-full" v-if="this.prevImages.length === 0"> -->
 <div class="carousel-example">
        <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
            <swiper-slide v-for="(tag, index) in tags" :key="tag.name" class="ss-width">
                <span class="mb-2" @click="setTag(tag.name)">
                    <b-link class="art-tag" :class="{ 'is-active': selectedStyle === tag.name }">
                        {{ tag.name }}
                    </b-link>
                </span>
            </swiper-slide>
        </swiper>
    </div>

    <!-- Image Uploader -->
    <div class="image-container" v-for="(image, index) in prevImages" :key="index">
    <feather-icon icon="XCircleIcon" class="img-close-button" svgClasses="h-7 w-7 text-primary" @click.stop="removeUploadImage(index)"></feather-icon>
    <img :src="image.url" alt="image thumbnail" @click="openImageViewer(image.url)" />
    </div>

    <!-- Image Viewer Modal -->
    <div v-if="isViewerOpen"  class="modal-content" @click.self="closeImageViewer">
    <button class="close-button" @click="closeImageViewer">&times;</button>
      <!-- Display only the selected image -->
  <div class="image-wrapper">
    <img :src="selectedImageUrl" alt="Selected Image" class="modal-image" />
  </div>
    </div>
    
    <div class="" v-if='isLoading'>
        <div class="loader-nft">
            <div class="inner one"></div>
            <div class="inner two"></div>
            <div class="inner three"></div>
        </div>
    </div>

    <div class="flex">
        <div class="w-1/12 hidden-sm">
        </div>
        <div>
            <vs-button type="filled" size="small" class="ai-art-btn" icon-pack="feather" icon="icon-zap" @click="generateImages" :disabled="!input.trim() || isLoading">
                <span>Create Memes</span>
            </vs-button>

        </div>
        <!-- <ProgressBar ref="uploadProgressbar" /> -->
        <div class="w-11/12 flex">
            <div class="img-btn">
                <vs-button color="dark" type="line" icon="image" size="small" @click="uploadImage">
                    <input ref="imageInput" type="file" accept="image/*" @change="onImageChange" multiple />
                </vs-button>
            </div>
        </div>

        <div class="og-card" v-if="hasOpenGraph">
            <vs-card v-if="!ogLoading" class="cursor-pointer z-50">
                <feather-icon icon="XCircleIcon" class="og-close-button" svgClasses="h-8 w-8 mb-1 text-primary" @click="hasOpenGraph = false"></feather-icon>
                <div class="static og-container" @click="goToWebsite">
                    <div>
                        <div slot="media" v-if="openGraphData.ogImage">
                            <img :src="openGraphData.ogImage.url" class="og-img" />
                        </div>
                        <vs-divider />
                        <div>
                            <h5 class="mt-2">{{ openGraphData.ogTitle | truncate(50) }}</h5>
                            <span v-if="openGraphData.ogSiteName">{{ openGraphData.ogSiteName  }}</span>
                            <p class="text-base">{{ openGraphData.ogDescription }}</p>
                        </div>
                    </div>
                </div>
            </vs-card>
            <div class="block loading" v-else>
                <div class="loading">
                    <div class="effect-1 effects"></div>
                    <div class="effect-2 effects"></div>
                    <div class="effect-3 effects"></div>
                </div>
            </div>
        </div>
        

        <div class="flex">
            <emoji-picker @emoji="insert" size="small" :search="search">
                <div class="emoji-invoker flex justify-center align-center h-full hidden-sm" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                    <feather-icon svgClasses="h-8 w-8 cursor-pointer p-1 rounded" class="mr-2" icon="SmileIcon"></feather-icon>
                </div>
                <div slot="emoji-picker" slot-scope="{ emojis }">
                    <div class="absolute z-10 border w-64 h-96 overflow-scroll p-4 rounded bg-white shadow t-4 emoji-select-panel">
                        <div class="flex">
                            <input class="flex-1 rounded-full border py-2 px-4 outline-none focus:shadow-outline" type="text" v-model="search" v-focus>
                        </div>
                        <div>
                            <div v-for="(emojiGroup, category) in emojis" :key="category">
                                <h5 class="text-grey-darker uppercase text-sm cursor-default mb-2 mt-4">{{ category }}</h5>
                                <div class="flex flex-wrap justify-between emojis">
                                    <span class="p-1 cursor-pointer rounded hover:bg-grey-light focus:outline-none focus:shadow-outline flex items-center justify-center h-6 w-6" v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)" :title="emojiName">{{ emoji }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </emoji-picker>
            <div class="flex">
                <vs-button @click="onSubmitPost" color="primary" type="filled" size="small" :disabled="(this.input.length === 0)">Post Now</vs-button>
            </div>
        </div>
    </div>

</vx-card>
</template>

<script>
import EmojiPicker from 'vue-emoji-picker'
import ImageUploader from 'vue-image-upload-resize'
import Avatar from 'vue-avatar';
// import ProgressBar from './ProgressBar'
import axios from 'axios';
import {
    v4 as uuidv4
} from 'uuid';
import {
    swiper,
    swiperSlide
} from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'

export default {
    props: {
        userImage: String
    },
    data() {
        return {
            input: '',
            characterDetails: '',
            search: '',
            prevImages: [],
            showPopup: false,
            videos: [],
            images: [],
            videos: [],
            hasOpenGraph: false,
            isViewerOpen: false,
            selectedImageUrl: null,

            // Generate Image
            hasImage: false,
            image: null,
            generatedImages: [],
            filename: '',
            post_category: [],
            isLoading: false,
            tags: [{
                    name: 'Comic book',
                },
                {
                    name: 'Pixar/Disney Character',
                },
                {
                    name: 'Line art'
                },
                {
                    name: 'Digital/Oil Painting'
                },
      
                {
                    name: 'Photographic',
                },
                {
                    name: 'Black and White Film Noir',
                },
                {
                    name: 'Japanese Anime'
                },
             
            ],
            selectedStyle: 'Comic book',

            settings: {
                maxScrollbarLength: 100,
                wheelSpeed: .60
            },

            swiperOption: {
                slidesPerView: 'auto',
                spaceBetween: 1,
                breakpoints: {
                    1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
                    768: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
                    640: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    }
                }
            }
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any() && this.input !== ''
        },
    },
    methods: {
        insert(emoji) {
            this.input += emoji
        },
        async onSubmitPost(e) {
        this.isLoading = true;
        e.preventDefault();
        if (!this.validateForm) return;

        const medias = [...this.images, ...this.videos];
        const postData = {
            author: this.$store.state.auth.userData.name,
            content: this.input,
            medias
        };

        // Clear data
        this.input = '';
        this.prevImages = [];
        this.images = [];
        this.videos = [];

        try {
            // Dispatch and wait for the operation to complete
            await this.$store.dispatch('newsfeed/createPost', postData);
            
            this.$vs.notify({
                time: 9500,
                title: 'Posted successfully!',
                iconPack: 'feather',
                icon: 'icon-check',
                position: 'top-right',
                color: 'success'
            });
        } catch (error) {
            console.error("Error while posting:", error);
            // Optionally notify the user about the failure
        }

        // Ensure reset happens after all processing is complete
        this.$nextTick(() => {
            this.resetTextareaHeight();
        });

        this.isLoading = false;
    },
    getFirstName(displayName) {
      return displayName.split(' ')[0];
    },
    resetTextareaHeight() {
        const textarea = this.$refs.textarea?.$el.querySelector('textarea');
        if (textarea) {
            textarea.style.height = 'auto';  // Reset to default height
        }
    },

    async generateImages() {
    this.isLoading = true;
    const userPrompt = this.input; 
    const imageStyle = this.selectedStyle;
    const characterStyle = this.characterDetails; 

    try {
        const eventSource = new EventSource(`https://generate.briks.ai/generate-meme?userPrompt=${userPrompt}&imageStyle=${imageStyle}&characterStyle=${characterStyle}`);

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.success && data.memeFile) {
                const newImage = { url: `https://generate.briks.ai/${data.memeFile}` };
                this.prevImages.push(newImage); // Add new image to prevImages
                this.addImageToPost(newImage); // Process and display the new image immediately
            } else if (data.message === "Process complete") {
                console.log("All memes generated:", data.memeFiles);
                this.isLoading = false;
                if (this.prevImages.length === 0) {
                    console.error("No images were generated.");
                }
            } else {
                console.error("Error generating meme:", data.error);
                this.isLoading = false;
            }
        };

        eventSource.onerror = (error) => {
            console.error("EventSource encountered an error:", error);
            this.isLoading = false;
            eventSource.close();
        };

    } catch (error) {
        console.error("Error during image generation request:", error);
        this.isLoading = false;
    }
},

addImageToPost(newImage) {
    fetch(newImage.url)
        .then(response => response.blob())
        .then(blob => {
            const file = new File([blob], `image-${Date.now()}.jpg`, {
                type: 'image/jpeg'
            });
            this.images.push(file);
        })
        .catch(error => {
            console.error("Error converting image to File:", error);
        });
},

        resizeTextarea(event) {
            const textarea = event.target;
            textarea.style.height = 'auto'; 
            textarea.style.height = '8px'; // Reset the height to shrink to the new content
            textarea.style.height = textarea.scrollHeight + 12 + 'px'; // Set the height based on the scroll height
        },
        uploadImage() {
            this.$refs.imageInput.click()
        },
        onImageChange(e) {
        this.prevImages = []; // Resets images array (remove if you want to keep existing images)
        this.images = e.target.files;
        for (const image of this.images) {
        const fr = new FileReader();
        fr.onload = (e) => {
            this.prevImages.push({
                name: image.name,
                type: image.type,
                url: e.target.result 
            });
        };
        fr.readAsDataURL(image);
        }
        },
        setTag(tagName) {
        if (this.selectedStyle === tagName) {
            // If the tag is already selected, deselect it
            this.selectedStyle = null;
        } else {
            // Set the selectedStyle to the clicked tag
            this.selectedStyle = tagName;
        }
        },
        removeUploadImage(index) {
            this.prevImages.splice(index, 1)
            this.images.splice(index, 1)
        },
        openImageViewer(imageUrl) {
    this.isViewerOpen = true;
    this.selectedImageUrl = imageUrl;
    window.addEventListener('keyup', this.handleKeyup);
  },
  closeImageViewer() {
    this.isViewerOpen = false;
    window.removeEventListener('keyup', this.handleKeyup);
  },
  handleKeyup(e) {
    if (e.key === "Escape") {
      this.closeImageViewer();
    }
  }

    },

    components: {
        EmojiPicker,
        ImageUploader,
        // VuePerfectScrollbar,
        swiper,
        swiperSlide,
        Avatar
    }
}
</script>

<style lang="css" scoped>
.custom-add-label {
    margin-right: 0.3em;
}

input[type="file"] {
    display: none;
}

#profile-page .post-input {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    color: #04226c !important;
    box-sizing: border-box !important;
    border-radius: 16px !important;
    padding: 10px 20px !important;  /* // Increased padding for larger text area */
    min-height: 60px !important;   /* // Minimum height to make the box larger */
    width: 100%;  /* // Ensures the textarea takes full width of its container */
}


.vs-popup {
    max-width: 80vw;
    /* Adjust based on preference */
    border-radius: 0;
    /* Remove rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Optional: adds subtle shadow */
}

.text-input {
    border: 1px solid #ccc;
    /* You may want to make this more subtle or change the color */
    padding: 8px;
    border-radius: 0;
    /* Removing rounded corners */
    resize: vertical;
}

.dalle-image.con-vs-popup .vs-popup--content {
    margin-top: 100px !important;
    margin-bottom: 0px !important;
}

.dalle-image.con-vs-popup .vs-popup--header {
    background-color: transparent !important;
}

.image-dalle {
    height: 220px;
    object-fit: cover;
    object-position: top;
}

.og-loading {
    width: 100%;
    height: 300px;
}

.og-card {
    min-height: 300px;
}

.img-close-button {
    position: absolute;
    top: 12px;
    /* Adjust this value to position the button correctly */
    right: 0px;
    /* Adjust this value to position the button correctly */
    cursor: pointer;
    background: #fff;
    /* Optional: adds background to the icon for better visibility */
    border-radius: 50%;
    /* Circular background */
    padding: 2px;
    /* Padding around the icon */
}

.hoverCard {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    /* width: 300px; */
    margin-top: 2px;
    width: 200px;
    z-index: 10000 !important;
    transition: visibility 0.1s linear, opacity 0.1s linear;
}

.og-close-button {
    position: absolute !important;
    z-index: 1000;
    right: 37px;
    top: 158px;
}

.og-img {
    object-fit: cover;
    width: 100%;
    max-height: 300px;
}

.image-container {
    position: relative;
    display: inline-block;
    /* Adjust depending on your layout needs */
    margin-right: 10px;
    /* Space between images */
    margin-bottom: 10px;
    /* Space below each image for consistent layout */
}

.image-container img {
    display: block;
    /* Ensures the image takes up the full size of its container */
    width: 110px;
    /* Fixed width, adjust as necessary */
    height: 110px;
    /* Fixed height, adjust as necessary */
    object-fit: cover;
    /* Ensures the image covers the area, adjust as necessary */
}

.object-cover {
    width: 100%;
    /* Takes full width of the container */
    height: 100%;
    /* Takes full height of the container */
}


.modal-content {
    position: fixed;
    top: 50%;
    left: 53.5%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 0px;
    border-radius: 0px;
    max-width: 40%; 
    max-height: 77%;
    z-index: 1000;
    overflow: auto; 
}

.modal-image {
    width: 100%;
    height: 100%
    /* display: block; */
    /* object-fit: cover; */
}

.close-button {
    position: fixed;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: #101010;
    font-size: 34px;
    background: none;
    border: black;
}

.vs-button.ai-art-btn {
    background-color: #4263a3 !important;
    border: 0px solid #4263a3 !important;
    border-radius: 0 !important;
    /* top: 50%; */
    left: 15%;
}

.img-btn .vs-button {
    border: 1px solid !important;
    border-color: rgba(var(--vs-dark), .2) !important;
    padding: 0.557rem 1.00rem !important;
    left: 65%;
}

.post-container {
    background-color: #fff; /* Keep the background consistent */
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Slightly stronger shadow for depth */
}


.character-details {
    display: flex; /* Ensures the label and input are on the same line */
    align-items: center; /* Vertically aligns the elements */
    margin-top: 8px; /* Spacing from the previous element */
}

.character-label {
    white-space: nowrap; /* Prevents the label from wrapping */
    margin-right: 8px; /* Space between the label and the input */
}

.character-input {
    flex-grow: 1; 
    color: #7a7a7a !important; 
    
    /* Allows the input to fill the remaining space */
}

.character-input .vs__input {
    border: none !important; /* Additional selector for some frameworks */
    box-shadow: none !important;
    
}

.post-header 
.post-header .character-details {
    margin-bottom: 10px; /* Adjust this value to increase or decrease the space between elements */
}

.post-header {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap as needed */
    align-items: flex-start; /* Aligns items at the start of the flex container */
}

.post-header, .post-header .character-details {
    width: 100%; /* Ensures these elements do not overflow their container */
}
.carousel-example
{
    margin-left: 40px;
    margin-right: 0px; /* Ensures these elements do not overflow their container */
}

.post-header {
    align-items: center; /* Vertically centers the items */
    justify-content: space-between; /* Distributes space between items */
}

.vs-textarea {
    margin-bottom: 10px; /* Space between the textarea and the next element */
}

.vs-avatar--con-img img { 
    display: flex;
    align-items: center;  /* This aligns the avatar vertically in the center */
    justify-content: center;  /* This centers the avatar horizontally */
}


.character-details-input {
    padding: 8px 12px;
    /* Comfortable padding within the input box */
}

.art-tag {
    font-size: .875rem !important;
    margin-bottom: 10px; /* Space between tags if they wrap to the next line */
    /* margin-top: 10px;  */
    padding: 0.28571rem 0.71429rem !important;
    display: inline-flex; /* Use inline-flex to maintain inline behavior with flexbox capabilities */
    align-items: left !important;
    justify-content: center;
    white-space: nowrap; /* Keeps the tag text in a single line */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
    overflow: hidden;
    border-radius: 20px; /* Rounded corners for aesthetic */
    border: 1px solid #ccc; /* Subtle border for definition */
    background-color: #f0f0f0; /* Background color */
    transition: all 0.2s ease-in-out; /* Smooth transition for hover effects */
}



.scroll-area--customizer {
    height: calc(100% - 5rem);

    &:not(.ps) {
        overflow-x: auto;
    }
}

.ss-width {
    width: auto !important;
}

/* Custom scrollbar */
#scrollbar {
    scrollbar-width: thin;
}

/* width */
#scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
#scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
#scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
#scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}



.art-tag:hover, .art-tag.is-active {
    color: #ffffff !important; 
    background-color: #0069d9; 
}

.swiper-slide {
    display: flex;
    align-items: 'auto';
    justify-content: center;
    padding: 5px; /* Adds padding inside each slide for spacing */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}


.swiper-container {
    width: 100%;            /* Ensures the swiper takes full width */
    overflow-x: auto;       /* Enables horizontal scrolling for the container */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    scrollbar-width: none;  /* Hides scrollbar in Firefox */
    -ms-overflow-style: none;  /* Hides scrollbar in IE and Edge */
}

.swiper-container::-webkit-scrollbar {
    display: none;  /* Hides scrollbar in WebKit browsers */
}

@media (max-width: 768px) {
    .post-header, .character-details {
        flex-direction: column; /* Stack flex children vertically on smaller screens */
    }
    .post-header div, .character-details div {
        width: 100%; /* Each child takes full width of the container */
    }
    .swiper-container {
        /* margin-top: 10px; */
        margin-left: 5px !important; /* Provide more space above the swiper on small screens */
    }
    .carousel-example
    {
    margin-left: 5px !important;
    margin-right: 5px !important; /* Ensures these elements do not overflow their container */
    }
    .modal-content {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 0px;
    border-radius: 0px;
    max-width: 80%; 
    max-height: 80%;
    z-index: 1000;
    overflow: auto; 
}

    .vs-button.ai-art-btn {
    background-color: #4263a3 !important;
    border: 0px solid #4263a3 !important;
    border-radius: 0 !important;
    /* top: 50%; */
    left: 12px;
    width: 100% !important;
    height: 100% !important;
}

}




</style>
